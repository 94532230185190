import React from 'react';
import { render, hydrate } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isPrerendering } from './lib/isPreRendering';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;
if (rootElement.hasChildNodes()) {
  hydrate(<App mockFetchLoading={isPrerendering} />, rootElement);
  // eslint-disable-next-line no-console
  console.log('hydrated');
} else {
  render(<App mockFetchLoading={isPrerendering} />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
