import { useMemo } from 'react';
import { Api } from './api';

const getNamespace = () => window.location.pathname.split('/')[1] ?? '';
const getBaseUrl = (): URL =>
  new URL(process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.reprobin.com:9023');

export type Props = {
  mockApi?: Api;
  mockFetchLoading?: boolean;
};

export const useApi = ({ mockApi, mockFetchLoading }: Props): Api => {
  const baseUrl = getBaseUrl();
  const api = useMemo(() => {
    if (mockFetchLoading) {
      // Api that always returns a pending promise
      return new Api(baseUrl, getNamespace(), () => new Promise<Response>(() => undefined));
    } else if (mockApi) {
      return mockApi;
    } else {
      return new Api(baseUrl, getNamespace());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return api;
};
