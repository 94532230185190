export class Api {
  constructor(private baseURL: URL, public namespace: string, private fetchOverride?: typeof fetch) {
    this.setNamespace(namespace);
  }

  public websocketURL(packages: string, id: string): string {
    const packageURL = packages
      .split(',')
      .map((v) => v.trim())
      .join('/')
      .toLowerCase();
    return `${this.websocketBaseURL()}${id}/${packageURL}`;
  }

  private async fetch(endpoint: string, id: string, init: Partial<RequestInit>): Promise<Response> {
    if (this.fetchOverride) {
      return await this.fetchOverride(`${this.apiBaseURL()}${id}${endpoint}`, {
        ...init,
      });
    } else {
      return await fetch(`${this.apiBaseURL()}${id}${endpoint}`, {
        ...init,
      });
    }
  }

  public setNamespace(namespace: string): string {
    this.namespace = namespace;
    return `https://reprobin.com/${namespace}`;
  }

  public apiBaseURL(): string {
    return `${this.baseURL.toString()}${this.namespace}`;
  }

  public websocketBaseURL(): string {
    const protocol = this.baseURL.protocol;
    return `${protocol === 'https:' ? 'wss' : 'ws'}://${this.baseURL.host}${this.baseURL.pathname}ws/${this.namespace}`;
  }

  async saveRun(body: RequestInit['body'], id = ''): Promise<Response> {
    return await this.fetch('/run', id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8 ',
      },
      body,
    });
  }

  async saveRunMetadata(packages: string, id = ''): Promise<Response> {
    const trimmedPackages = packages.split('\n')[0] ?? '';
    return await this.fetch('/run-metadata', id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8 ',
      },
      body: trimmedPackages,
    });
  }

  async publish(body: RequestInit['body']): Promise<Response> {
    return await this.fetch('/input', '', {
      method: 'PUT',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8 ',
      },
      body,
    });
  }

  async publishMetadata(packages: string): Promise<Response> {
    const trimmedPackages = packages.split('\n')[0] ?? '';
    return await this.fetch('/input-metadata', '', {
      method: 'PUT',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8 ',
      },
      body: trimmedPackages,
    });
  }

  async load(): Promise<string> {
    const response = await this.fetch('/input', '', { method: 'GET' });
    if (response.status === 404) {
      throw Error('not found');
    }
    return await response.text();
  }

  async loadMetadata(): Promise<string> {
    const response = await this.fetch('/input-metadata', '', { method: 'GET' });
    if (response.status === 404) {
      throw Error('not found');
    }
    return await response.text();
  }
}
