import React, { useRef } from 'react';

import { CodeMirror, customTheme, listenChangesExtension } from 'src/components/CodeMirror';
import { EditorView, keymap } from '@codemirror/view';
import { python } from '@codemirror/lang-python';
import { indentWithTab } from '@codemirror/commands';

export type Props = {
  className?: string;
  initialDoc: string;
  setDoc: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const CodeBox: React.FC<Props> = React.memo(({ className, initialDoc, setDoc }) => {
  const { current: pythonExtension } = useRef(python());
  const { current: editableHandler } = useRef(EditorView.editable.of(true));
  const { current: keymapExtension } = useRef(keymap.of([indentWithTab]));
  const { current: changeListener } = useRef(
    listenChangesExtension((transaction) => {
      const newInput = transaction.newDoc.toJSON();
      setDoc(newInput.join('\n'));
    }),
  );

  return (
    <CodeMirror
      extensions={[pythonExtension, customTheme, editableHandler, keymapExtension, changeListener]}
      doc={initialDoc}
      className={className}
      ariaLabel="code input"
    />
  );
});
