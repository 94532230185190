import React, { useState, useEffect, useMemo } from 'react';
import './scss/app.scss';
import { Header } from 'src/components/Header';
import { Editor } from 'src/components/Editor';
import { PublishModal } from 'src/components/PublishModal';
import { AppContainer } from './App.styles';
import { Api, useApi, Props as ApiProps } from './lib';

export type Props = ApiProps;

export const App: React.FC<Props> = ({ mockApi, mockFetchLoading }) => {
  const api = useApi({ mockApi, mockFetchLoading });
  const runId = useMemo(() => naiveRandomStr(), []);
  const [doc, setDoc, packages, setPackages] = useReproState(api);
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);
  const [link, setLink] = useState<string | undefined>();
  const [isRunning, setIsRunning] = useState(false);
  const [runCacheId, setCacheId] = useState(0);

  const onRun = async () => {
    try {
      setIsRunning(true);
      await api.saveRun(doc, runId);
      await api.saveRunMetadata(packages, runId);
      setCacheId((n) => n + 1);
    } catch (err) {
      setIsRunning(false);
    }
  };

  const onPublish = async () => {
    const newLink = api.setNamespace(naiveRandomStr());
    await api.publishMetadata(packages);
    await api.publish(doc);
    setLink(newLink);
  };

  return (
    <AppContainer>
      <PublishModal
        show={isPublishModalVisible}
        onHide={() => setPublishModalVisible(false)}
        onPublish={onPublish}
        link={link}
      />
      <Header
        onRun={onRun}
        onSave={() => setPublishModalVisible(true)}
        isRunning={isRunning}
        packages={packages}
        onChangePackages={setPackages}
      />
      <Editor
        initialDoc={doc}
        setDoc={setDoc}
        runCacheId={runCacheId}
        websocketUrl={api.websocketURL(packages, runId)}
        websocketOnClose={() => setIsRunning(false)}
      />
    </AppContainer>
  );
};

function useReproState(
  api: Api,
): [
  string | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>,
  string,
  React.Dispatch<React.SetStateAction<string>>,
] {
  const [doc, setDoc] = useState<string>();
  const [packages, setPackages] = useState<string>('');

  useEffect(() => {
    void api
      .load()
      .then((res) => {
        setDoc(res);
      })
      .catch((err) => {
        setDoc('#!/bin/bash\ncowsay foo\n');
      });
    void api
      .loadMetadata()
      .then((pkgs) => {
        setPackages(pkgs);
      })
      .catch((err) => {
        setPackages('cowsay');
      });
  }, [api]);
  return [doc, setDoc, packages, setPackages];
}

const naiveRandomStr = () => (Math.random().toString(36) + '00000000000000000').slice(2, 18);

export default App;
