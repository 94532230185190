import React from 'react';
import Form from 'react-bootstrap/Form';

import { NixPkgSelector } from './NixPkgSelector';

export type Props = {
  onChangePackages: React.Dispatch<React.SetStateAction<string>>;
  packages: string;
};

export const HeaderSettings: React.FC<Props> = ({ packages, onChangePackages }) => {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Environment</Form.Label>
        <NixPkgSelector
          packages={packages}
          onSelectPkgs={(pkgs) => {
            const commaSeparatedPkgs = pkgs.map((pkg) => pkg.value).join(', ');

            onChangePackages(commaSeparatedPkgs);
          }}
        />
        <Form.Text className="text-muted">List of Nix packages to include in the env</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Syntax highlighting</Form.Label>
        <Form.Select>
          <option>Python</option>
        </Form.Select>
      </Form.Group>
    </Form>
  );
};
