import React, { useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { CodeBox, Props as CodeBoxProps } from 'src/components/CodeBox';
import { OutputTerminal } from 'src/components/Terminal';
import { InputCol, OutputCol } from './Editor.styles';
import { EditorSkeleton } from './Editor.skeleton';

export type Props = {
  initialDoc: string | undefined;
  runCacheId: number;
  websocketUrl: string;
  websocketOnClose: () => void;
} & Omit<CodeBoxProps, 'initialDoc'>;

export const Editor: React.FC<Props> = ({ runCacheId, initialDoc, setDoc, websocketUrl, websocketOnClose }) => {
  const terminalParent = useRef<HTMLDivElement | null>(null);
  const initialOutput =
    runCacheId === 0
      ? 'Hello! \x1B[1;3;31mReprobin\x1B[0m, allows you to quickly run code online.\r\n\r\nTo start, click ▶ above.'
      : undefined;

  return initialDoc === undefined ? (
    <EditorSkeleton />
  ) : (
    <Container fluid className="p-0">
      <Row className="m-0 d-flex justify-content-center mw-100">
        <InputCol xxl={5} lg={6} className="p-0 half-page">
          <CodeBox initialDoc={initialDoc} setDoc={setDoc} className="h-100 overflow-scroll" />
        </InputCol>
        <OutputCol
          xxl={5}
          lg={6}
          className="p-0 align-items-stretch flex-column bg-black half-page"
          ref={terminalParent}
        >
          <OutputTerminal
            parent={terminalParent}
            key={runCacheId}
            websocketUrl={websocketUrl}
            websocketOnClose={websocketOnClose}
            initialOutput={initialOutput}
            className="h-100 d-flex"
          />
        </OutputCol>
      </Row>
    </Container>
  );
};
