import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

export const OutputCol = styled(Col)`
  // https://github.com/xtermjs/xterm.js/issues/1283
  box-sizing: content-box !important;
  overflow: hidden;
`;
export const InputCol = styled(Col)`
  background-color: #292d3e;
`;
