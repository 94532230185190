import React, { useRef, useEffect } from 'react';
import { EditorState, EditorView, basicSetup } from '@codemirror/basic-setup';
import { Extension } from '@codemirror/state';

type Props = {
  className?: string;
  extensions: Extension[];
  doc?: string;
  disableDefaultExtensions?: boolean;
};

type TestingProps = Partial<{ role: string; ariaLabel: string; dataTestid: string }>;

function bindEditor(
  parent: HTMLDivElement,
  extensions: Extension[],
  doc = '',
  disableDefaultExtensions = false,
): EditorView {
  const defaultExtensions = disableDefaultExtensions ? [] : (basicSetup as Extension[]);

  const view = new EditorView({
    state: EditorState.create({
      extensions: [...defaultExtensions, ...extensions],
      doc,
    }),
    parent,
  });
  return view;
}

export const CodeMirror: React.FC<Props & TestingProps> = ({
  className,
  extensions,
  doc,
  disableDefaultExtensions = false,
  ariaLabel,
}) => {
  const textInput = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!textInput.current) {
      return;
    }
    const view = bindEditor(textInput.current, extensions, doc, disableDefaultExtensions);

    return () => view.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput, disableDefaultExtensions, ...extensions]);

  return <div ref={textInput} role="textbox" aria-label={ariaLabel} className={className}></div>;
};
