import { ReactComponent as LogoSVG } from 'src/assets/logo.svg';
import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const StatusContainer = styled.div.attrs({
  id: 'status-text',
  className: 'd-none d-md-block flex-grow-1 text-muted text-end me-2',
})`
  animation: ${fadeOut} ease 3s;
  opacity: 0;
`;

export const Logo = styled(LogoSVG)`
  height: 35px;
  width: 35px;
  margin-right: 0.5em;
`;
