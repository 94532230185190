import { IDisposable, Terminal } from 'xterm';

export class InitialContent {
  private disposables: IDisposable[] = [];
  private isInitialized = false;

  constructor(private content: string) {}

  activate(terminal: Terminal): void {
    if (!this.isInitialized) {
      terminal.write(this.content);
    }
    this.isInitialized = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispose(): void {}
}
