import { EditorView } from '@codemirror/view';
import { HighlightStyle, tags as t } from '@codemirror/highlight';

// Inspired by
// https://github.com/material-theme/vsc-material-theme/blob/master/scripts/generator/settings/specific/palenight-hc.ts

const c = {
  // Custom
  RaisinBlackLight: '#292D3E',
  RaisinBlackDark: '#717CB450',
  RaisinBlackDarkest: '#1c1f2b',
  DarkBlueGray: '#676E95',
  WildBlueYonder: '#A6ACCD',
  Independence: '#3A3F58',
  Halfbaked: '#79c6d9',
  Danube: '#629ccd',
  AstronautBlue: '#114367',
  YellowGreen: '#c3e891',
  CandleLight: '#fed82f',
  // Default
  Coral: '#e06c75',
  Cyan: '#56b6c2',
  Ivory: '#abb2bf',
  Stone: '#7d8799',
  Malibu: '#61afef',
  Whiskey: '#d19a66',
};

const background = c.RaisinBlackLight;
const activeLineBackground = c.RaisinBlackDarkest;
const text = c.WildBlueYonder;
const selection = c.RaisinBlackDark;
const invalid = '#ffffff';

const editorStyle = EditorView.theme(
  {
    '&': {
      color: text,
      backgroundColor: background,
      minHeight: '100%',
    },
    '.cm-content, .cm-gutter': { minHeight: '100%' },
    '.cm-gutters': {
      backgroundColor: background,
      color: c.Independence,
      border: 'none',
    },
    '.cm-activeLineGutter, .cm-activeLine': {
      backgroundColor: activeLineBackground,
    },
    '&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection': {
      backgroundColor: selection,
    },
    '.cm-selectionMatch': {
      backgroundColor: c.AstronautBlue,
      outline: '1px solid #457dff',
    },
    '.cm-cursor': {
      'border-left': `1px solid ${c.CandleLight}`,
    },
  },
  { dark: true },
);

const highlightStyle = HighlightStyle.define([
  { tag: t.paren, color: c.CandleLight },
  { tag: t.keyword, color: c.Halfbaked },
  { tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName], color: c.Ivory },
  { tag: [t.function(t.variableName), t.labelName], color: c.Malibu },
  { tag: [t.color, t.constant(t.name), t.standard(t.name)], color: c.Whiskey },
  { tag: [t.definition(t.name), t.separator], color: c.Ivory },
  {
    tag: [t.typeName, t.className, t.number, t.changed, t.annotation, t.modifier, t.self, t.namespace],
    color: 'violet',
  },
  { tag: [t.operator, t.operatorKeyword, t.url, t.escape, t.regexp, t.link, t.special(t.string)], color: c.Cyan },
  { tag: [t.meta, t.comment], color: c.Stone },
  { tag: t.strong, fontWeight: 'bold' },
  { tag: t.emphasis, fontStyle: 'italic' },
  { tag: t.strikethrough, textDecoration: 'line-through' },
  { tag: t.link, color: c.Stone, textDecoration: 'underline' },
  { tag: t.heading, fontWeight: 'bold', color: c.Coral },
  { tag: [t.atom, t.bool, t.special(t.variableName)], color: c.Whiskey },
  { tag: [t.processingInstruction, t.string, t.inserted], color: c.YellowGreen },
  { tag: t.invalid, color: invalid },
]);
export const customTheme = [editorStyle, highlightStyle];
