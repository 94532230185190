import { useRef, RefObject, useEffect } from 'react';

export function useResizeObserver(ref: RefObject<HTMLElement | null>, callback: CallableFunction): void {
  const observer = useRef<ResizeObserver | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (!observer.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const r = new ResizeObserver(() => callback());
      observer.current = r;
      observer.current.observe(ref.current);

      return () => {
        r.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
