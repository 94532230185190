import { StateField, Transaction } from '@codemirror/state';

export const listenChangesExtension = (callback: (transaction: Transaction) => void): StateField<null> =>
  StateField.define({
    create: () => null,
    update: (_dummyValue, transaction) => {
      if (transaction.docChanged) {
        callback(transaction);
      }
      return null;
    },
  });
