import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Collapse from 'react-bootstrap/Collapse';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as SaveIcon } from 'src/assets/icons/icon_save.svg';
import { ReactComponent as RunIcon } from 'src/assets/icons/icon_run.svg';
import { ReactComponent as CogIcon } from 'src/assets/icons/icon_cog.svg';
import { ReactComponent as HelpIcon } from 'src/assets/icons/icon_help.svg';
import { ReactComponent as ExternalIcon } from 'src/assets/icons/icon_external.svg';
import { statusText } from 'src/lib/statusText';
import { HeaderSettings, Props as HeaderSettingsProps } from './HeaderSettings';
import { Logo, StatusContainer } from './Header.styles';

export type Props = {
  onRun: () => void;
  onSave: () => void;
  isRunning: boolean;
} & HeaderSettingsProps;

export const Header: React.FC<Props> = ({ onRun, onSave, onChangePackages, isRunning, packages }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Navbar bg="light">
        <Container fluid>
          <Logo />
          <Navbar.Brand className="flex-grow-1 d-none d-sm-block">Reprobin</Navbar.Brand>
          <StatusContainer />
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end flex-grow-0" style={{ gap: '0.5em' }}>
            <Button
              variant="outline-success"
              aria-label="run"
              className="btn-md icon"
              onMouseEnter={() => statusText('Run code')}
              onClick={() => {
                setIsOpen(false);
                onRun();
              }}
              disabled={isRunning}
            >
              {isRunning ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : <RunIcon />}
            </Button>
            <HelpDropdown />
            <Button
              variant="outline-secondary"
              className="btn-md icon"
              active={isOpen}
              onMouseEnter={() => statusText('Environment settings')}
              onClick={() => setIsOpen(!isOpen)}
            >
              <CogIcon />
            </Button>
            <Button
              variant="outline-primary"
              className="btn-md icon"
              onMouseEnter={() => statusText('Save and publish')}
              onClick={() => {
                setIsOpen(false);
                onSave();
              }}
              disabled={isRunning}
            >
              <SaveIcon />
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Collapse in={isOpen}>
        <Container className="my-1">
          <HeaderSettings packages={packages} onChangePackages={onChangePackages} />
        </Container>
      </Collapse>
    </>
  );
};

const HelpDropdown: React.FC = () => (
  <Dropdown>
    <Dropdown.Toggle
      variant="outline-secondary"
      id={'dropdown-help'}
      role={'dropdown-help'}
      className="btn btn-md icon fill-secondary text-secondary pe-2"
    >
      <HelpIcon />
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="/serve/article/" target="_blank">
        About
      </Dropdown.Item>
      <Dropdown.Item href="/serve/article/" target="_blank">
        How it works
      </Dropdown.Item>
      <Dropdown.Item href="/serve/article/" target="_blank">
        Legal
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Examples</Dropdown.Header>
      <Dropdown.Item href="/python" target="_blank">
        Run Python <ExternalIcon style={{ width: 'var(--icon-sm)', height: 'var(--icon-sm)' }} />
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);
