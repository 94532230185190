import React, { useMemo } from 'react';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

import { NixPkgsApi, PkgSearchResult } from 'src/lib';

type NixPkgOption = {
  value: string;
  label: string;
};

const pkgSearchResultToOptions = (res: PkgSearchResult): NixPkgOption[] => {
  return res.map((hit) => {
    return {
      value: hit,
      label: hit,
    };
  });
};

const searchPkgs = (pkg: string, callback: (opts: NixPkgOption[]) => void) => {
  if (pkg !== '') {
    void NixPkgsApi.search(pkg).then((pkgSearchResult) => {
      callback(pkgSearchResultToOptions(pkgSearchResult));
    });
  }
};

const debouncedSearchPkgs = debounce(searchPkgs, 500);

type Props = {
  onSelectPkgs: (pkgs: MultiValue<NixPkgOption>) => void;
  packages: string;
};

export const NixPkgSelector: React.FC<Props> = ({ onSelectPkgs, packages }) => {
  const packageOptions = useMemo(() => {
    return packages ? pkgSearchResultToOptions(packages.split(',').map((v) => v.trim())) : undefined;
  }, [packages]);

  return (
    <AsyncSelect
      isMulti
      isSearchable
      cacheOptions
      onChange={onSelectPkgs}
      value={packageOptions}
      loadOptions={debouncedSearchPkgs}
    />
  );
};
