import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import { Terminal as Xterm, ITerminalOptions, ITerminalAddon } from 'xterm';
import debounce from 'lodash.debounce';
import { useResizeObserver } from 'src/lib/useResizeObserver';
import { FitAddon } from 'xterm-addon-fit';
import { AttachAddon } from 'xterm-addon-attach';
import 'xterm/css/xterm.css';
import { InitialContent } from './initialContentAddon';

export type Props = {
  addons?: ITerminalAddon[];
  className?: string;
  options?: ITerminalOptions;
  disableDefaultExtensions?: boolean;
  parent: React.RefObject<HTMLDivElement | null>;
  disableStdin?: boolean;
  onMount?: () => void;
  onResize?: () => void;
};

export type TestingProps = Partial<
  Pick<HTMLElement, 'title'> & { role: string; ariaLabel: string; dataTestid: string }
>;

export type OutputTerminalProps = {
  addons?: ITerminalAddon[];
  className?: string;
  disableDefaultExtensions?: boolean;
  parent: React.RefObject<HTMLDivElement | null>;
  disableStdin?: boolean;
  initialOutput?: string;
  websocketUrl: string;
  websocketOnClose?: () => void;
};

function bindTerminal(parent: HTMLDivElement, addons?: ITerminalAddon[], options?: ITerminalOptions): Xterm {
  const terminal: Xterm = new Xterm(options);
  if (addons) {
    addons.forEach((addon) => {
      terminal.loadAddon(addon);
    });
  }
  terminal.open(parent);
  return terminal;
}

export const OutputTerminal: React.FC<OutputTerminalProps & TestingProps> = ({
  addons,
  disableStdin,
  websocketUrl,
  websocketOnClose,
  initialOutput,
  ...props
}) => {
  // All addons should be instantiated only once during lifetime of terminal
  const fitAddon = useMemo(() => new FitAddon(), []);

  const contentAddon: ITerminalAddon = useMemo(() => {
    if (initialOutput) {
      // addon that populates terminal
      return new InitialContent(initialOutput);
    } else {
      // addon attached to websocket
      const socket = new WebSocket(websocketUrl);
      if (websocketOnClose) {
        socket.addEventListener('close', websocketOnClose);
      }
      return new AttachAddon(socket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allAddons = useMemo(() => [...(addons ?? []), fitAddon, contentAddon], []);

  const onMount = useCallback(() => {
    fitAddon?.fit();
  }, [fitAddon]);
  const onResize = useCallback(() => {
    fitAddon?.fit();
  }, [fitAddon]);

  return (
    <Terminal
      disableStdin={disableStdin}
      className="d-flex flex-fill"
      addons={allAddons}
      onMount={onMount}
      onResize={onResize}
      {...props}
    />
  );
};

export const Terminal: React.FC<Props & TestingProps> = ({
  addons,
  ariaLabel,
  className,
  options,
  parent,
  disableStdin,
  onMount,
  onResize,
}) => {
  const terminalElem = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!terminalElem.current) {
      return;
    }

    const terminal = bindTerminal(terminalElem.current, addons ?? [], {
      disableStdin,
      theme: {
        background: 'black',
      },
      rows: 15,
      fontSize: 15,
      fontFamily: 'monospace',
      ...options,
    });

    onMount && onMount();

    return () => terminal.dispose();
  }, [options, disableStdin, addons, onMount]);

  useDebouncedResizeObserver(parent, () => {
    try {
      onResize && onResize();
    } catch (e) {
      // ignore errors
    }
  });

  return <div ref={terminalElem} role="textbox" aria-label={ariaLabel} className={className}></div>;
};

const useDebouncedResizeObserver = (ref: React.RefObject<HTMLElement | null>, callback: () => void, wait = 250): void =>
  useResizeObserver(ref, debounce(callback, wait));
