import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentLoader from 'react-content-loader';

export const EditorSkeleton: React.FC = () => (
  <Container fluid className="p-0">
    <Row className="flex-xxl-nowrap g-0 flex-fill gap-2">
      <Col xl={true} className="">
        <ContentLoader
          viewBox="0 0 800 324"
          width={800}
          height={324}
          style={{ width: '100%' }}
          preserveAspectRatio="none"
        >
          <rect x="0" y="0" rx="5" ry="5" width="800" height="324px" />
        </ContentLoader>
      </Col>
      <Col xl={true}>
        <ContentLoader
          viewBox="0 0 800 324"
          width={800}
          height={324}
          style={{ width: '100%' }}
          preserveAspectRatio="none"
        >
          <rect x="0" y="0" rx="5" ry="5" width="800" height="324px" />
        </ContentLoader>
      </Col>
    </Row>
  </Container>
);
