import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export type Props = {
  show: boolean;
  onHide: () => void;
  onPublish: () => Promise<void>;
  link: string | undefined;
};

export const PublishModal: React.FC<Props> = ({ show, onHide, onPublish, link }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Publish</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="font-monospace">{link}</p>
      <p className="text-secondary" style={{ fontSize: '0.8rem' }}>
        All user-generated content is licensed under CC0 and may expire at any time. The service is provided as-is
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onPublish}>
        Publish
      </Button>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);
